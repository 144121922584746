<template>
  <v-card flat :style="`height: ${ windowSize.y }px; position: 'relative'`" class="pa-1 product-view" v-resize="onResizeHandler">
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card-text class="pa-0">
      <v-form ref="receiveForm" v-on:submit.prevent>
        <v-row>
          <v-col cols="12" class="pb-0 pt-0 font-weight-bold text-uppercase">
            {{ orderModel.number }}
            <template v-if="modeOfView === CONTSTANTS.RECEIVE">({{ $t('message.order.receiving') }})</template>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0 pr-1 pb-0 mt-1">
            <barcode-scanner v-model="scanProduct" :search="scanProduct" :scannerPayload="scannerPayload"
            :loading="productLoading"></barcode-scanner>
            <!-- <v-text-field outlined dense hide-details v-model="scanProduct" flat autocomplete="off"
              ref="scanProduct" @change="getRespectiveProductOrder" :loading="productLoading"
            :placeholder="$t('message.order.scanProduct')"></v-text-field> -->
          </v-col>
          <v-col cols="12" class="pt-1 pb-1 font-weight-bold" v-if="productFound">
            <span v-if="!isNewProduct">{{ productInfo.productname || '' }}</span>
            <span v-else class="red--text">{{ productInfo.name || '' }}</span>
          </v-col>
        </v-row>
        <v-row v-if="productFound">
          <v-col cols="6" class="pt-1 px-1 pb-0">
            <!-- <v-text-field v-if="!locationSelectedAlready" outlined dense hide-details v-model="selectedLocation" flat autocomplete="off"
              :rules="$_requiredValidation" @change="checkIfLocationValid" :loading="loadingLocation"
              ref="scanLocation" :placeholder="$t('message.product.scanLoc')"></v-text-field> -->
              <v-autocomplete v-if="!locationSelectedAlready" :items="listOfLocations" v-model="selectedLocation" item-text="name" item-value="name"
                hide-details outlined :loading="loadingLocation" :label="$t('message.product.scanLoc')" flat autocomplete="off"
                dense :rules="$_requiredValidation" ref="scanLocation" @change="checkIfLocationValid"></v-autocomplete>
          </v-col>
          <v-col cols="6" class="pt-1 px-1 pb-0"  v-if="productFound && productInfo.isbatch && modeOfView === CONTSTANTS.COLLECT">
            <template>
                <!-- <v-text-field outlined dense hide-details v-model="selectBatch" flat autocomplete="off"
                :rules="$_requiredValidation" @change="checkIfBatchContainsQty" :placeholder="$t('message.product.batch')" ref="batchRef"
                :loading="loadingBatch"></v-text-field> -->
                <v-autocomplete :items="listOfBatchNumbers" v-model="selectBatch"
                  item-text="batchnumberpwa" item-value="batchnumberpwa" hide-details outlined :loading="loadingBatch" :label="$t('message.product.batch')" flat autocomplete="off"
                  dense :rules="$_requiredValidation" :placeholder="$t('message.product.batch')" ref="batchRef" @change="checkIfBatchContainsQty"></v-autocomplete>
            </template>
          </v-col>
        </v-row>
        <v-row v-if="productFound">
          <template v-if="modeOfView === CONTSTANTS.RECEIVE">
            <v-col :cols="modeOfView === CONTSTANTS.RECEIVE ? '8' : '10'" class="pa-1 pl-2">
              <!-- <v-card-text class="pa-1 pl-2"> -->
                <span class="subheading">{{ $t('message.order.availableToPick') }}</span>
                <v-chip-group v-model="receivedObjIndex" active-class="primary text--accent-5" mandatory>
                  <v-chip v-for="(picked, index) in availableItems" :key="index" :disabled="picked.disabled" :value="picked.id">
                    {{ picked.picked_quantity }}<!-- pickedqty -->
                  </v-chip>
                </v-chip-group>
              <!-- </v-card-text> -->
            </v-col>
          </template>
          <template v-else>
            <v-col cols="9" class="pa-1">
              <v-row>
                <v-col cols="3" class="text-center py-0 mt-1" @click="decreaseQty">
                  <v-btn color="info" fab x-small>
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <v-text-field outlined dense hide-details type="number" class="text-right" v-model="pickQty"
                    autocomplete="off" ref="qtyRef" :rules="$_qtyValidation" :placeholder="$t('message.product.qty')"></v-text-field>
                </v-col>
                <v-col cols="3" class="text-center py-0 mt-1" @click="increaseQty">
                  <v-btn color="info" fab x-small>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </template>
          <v-col :cols="modeOfView === CONTSTANTS.RECEIVE ? '3' : '2'" class="pa-1 mt-2">
            <v-btn color="primary" small :loading="saveLoading" @click="receiveQtyOnOrder" :disabled="!(availableItems && availableItems.length > 0) && modeOfView === CONTSTANTS.RECEIVE">
              <template v-if="modeOfView === CONTSTANTS.RECEIVE">{{ $t('message.order.receive') }}</template>
              <template v-else>{{ $t('message.order.pick') }}</template>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <!-- LIST OF PRODUCTS -->
    <v-card-text class="highlight pt-0 pb-0 pl-1 mt-2">
      <v-row>
        <v-col cols="4" class="pa-0 pl-3 font-weight-bold">{{ $t('message.whsDashboard.product') }}:</v-col>
        <v-col cols="2" class="pa-0 pr-2 text-center font-weight-bold">{{ $t('message.ccsheet.unit') }}</v-col>
        <v-col cols="2" class="pa-0 text-right font-weight-bold">{{ $t('message.order.ordered') }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.order.pickedQty') }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.order.received') }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-divider class="pa-0"></v-divider>
        </v-col>
      </v-row>
      <!-- Load list of products -->
      <v-row class="pb-3" v-for="(product, index) in listOfProducts" :key="index" @click="scanProductByClick(product.productnumber)">
        <v-col cols="4" class="pa-0 pl-3 text-truncate" :title="product.productnumber" :class="{ 'pb-1': (index === listOfProducts.length - 1)}">
          {{ product.productnumber }}
          <br />
          <span>{{ product.productname }}</span>
        </v-col>
        <v-col cols="2" class="pa-0 text-center text-truncate" :title="product.unitname">{{ product.unitname }}</v-col>
        <v-col cols="2" class="pa-0 text-right text-truncate">{{ product.qty | absoluteNumber }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right text-truncate">{{ product.pickedqty_ref | absoluteNumber }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right text-truncate">
          {{ product.receivedqty_ref | absoluteNumber }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-btn small color="primary" dark absolute bottom left tabindex="-1" @click="$router.push(`/transfer_orders/${orderId}`)">
      <v-icon left>mdi-arrow-left-bold</v-icon>{{ $t('message.login.back') }}
    </v-btn>
    <v-btn small color="primary" dark absolute bottom right tabindex="-1" @click="navigateToView">
      <template v-if="modeOfView === CONTSTANTS.RECEIVE">
        {{ $t('message.order.collect') }}
      </template>
      <template v-else>
        {{ $t('message.order.receive') }}
      </template>
    </v-btn>
  </v-card>
</template>
<script>
import CONTSTANTS from '@/assets/json/constants.json'
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
import moment from 'moment'
import _ from 'lodash'
export default {
  data () {
    return {
      loading: false,
      orderId: 0,
      listOfProducts: [],
      orderModel: {},
      scanProduct: '',
      windowSize: {
        x: 0,
        y: 0
      },
      productFound: false,
      productInfo: {},
      pickQty: '',
      receivedObj: 0,
      receivedObjIndex: -1,
      listOfLocations: [],
      listOfBatchNumbers: [],
      selectedLocation: '',
      selectedLocationId: 0,
      selectBatch: '',
      saveLoading: false,
      loadingBatch: false,
      productLoading: false,
      loadingLocation: false,
      modeOfView: this.$route.params.mode || 'collect',
      availableItems: [],
      isNewProduct: false,
      locationSelectedAlready: false,
      CONTSTANTS: CONTSTANTS,
      /* Scanner field */
      scannerPayload: {
        placeholder: 'message.order.scanProduct',
        change: this.getRespectiveProductOrder,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      }
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  components: {
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  mounted () {
    this.loading = true
    this.orderId = this.$route.params.order_id
    this.getOrderAndProducts()
    /* this.getOrderProducts()
    this.getOrderInfo() */
    this.onResizeHandler()
    this.focusField('scanProduct')
  },
  watch: {
    pickQty (val) {
      const pickedQty = parseFloat(this.$formatter.replaceCommaWithDot(val || 0))
      const products = this.$formatter.cloneVariable(this.listOfProducts)
      let getFoundProd = products.find(x => x.productid === this.productInfo.productid)
      if (getFoundProd || this.isNewProduct) {
        var totalQty = 0
        var pickedCount = 0
        // for products that have not been planned
        if (!getFoundProd) {
          getFoundProd = {}
          getFoundProd.qty = val
          getFoundProd.pickedorders = []
        }
        if (getFoundProd.pickedorders) {
          for (let i = 0; i < getFoundProd.pickedorders.length; i++) {
            if (i !== getFoundProd.pickedorders.length) {
              const pickedQty = this.$formatter.replaceCommaWithDot(getFoundProd.pickedorders[i].picked_quantity)
              pickedCount += parseFloat(pickedQty)
            }
          }
        }
        totalQty += parseFloat(pickedCount)
        if (pickedQty) {
          const parentReceivedQty = pickedQty
          totalQty += parentReceivedQty
        }
        const orderedQty = this.$formatter.replaceCommaWithDot(getFoundProd.qty)
        const getOnStockFromLocation = this.listOfLocations.find(x => x.locationid === +this.selectedLocationId)
        if (parseFloat(orderedQty) < totalQty || (getOnStockFromLocation && getOnStockFromLocation.on_stock < totalQty)) {
          totalQty = parseFloat(orderedQty) - parseFloat(pickedCount)
          totalQty = getOnStockFromLocation.on_stock < totalQty ? getOnStockFromLocation.on_stock : totalQty
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
          setTimeout(() => {
            this.pickQty = totalQty
          })
        }
        if (this.productInfo.isbatch) this.checkIfBatchContainsQty()
      }
    }
  },
  methods: {
    getOrderAndProducts () {
      hostAppApi.get(`${this.getHostRefApi}warehouseorders/get_products_by_order/${this.orderId}?user_id=${this.userId}`)
        .then((response) => {
          if (response.data) {
            const { order, products } = response.data
            this.orderModel = order
            if (this.modeOfView === CONTSTANTS.RECEIVE) this.getDestinationWarehouseLocations()
            this.listOfProducts = []
            if (products) {
              for (let i = 0; i < products.length; i++) {
                let receivedCount = 0
                for (let j = 0; j < products[i].receivedorders.length; j++) {
                  const receivedQty = this.$formatter.replaceCommaWithDot(products[i].receivedorders[j].received_quantity || 0)
                  receivedCount += parseFloat(receivedQty || 0)
                }
                products[i].received_quantity = ''
                products[i].receivedqty_ref = receivedCount
                let pickedCount = 0
                for (let j = 0; j < products[i].pickedorders.length; j++) {
                  const pickedQty = this.$formatter.replaceCommaWithDot(products[i].pickedorders[j].picked_quantity)
                  pickedCount += parseFloat(pickedQty)
                }
                products[i].picked_quantity = ''
                products[i].pickedqty_ref = pickedCount
              }
              this.listOfProducts = products
            }
            this.restorePreset()
            this.productInfo = {}
            this.scanProduct = ''
            this.productFound = false
            this.focusField('scanProduct')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getOrderInfo () {
      const model = {
        filter: `id eq '${this.orderId}'`
      }
      this.$api.execute('post', 'warehouseorders/query', model)
        .then((response) => {
          if (response.data) {
            this.orderModel = response.data[0]
            if (this.modeOfView === CONTSTANTS.RECEIVE) this.getAllLocations()
          }
        })
    },
    getAvailableLocationsHandler (productid) {
      this.loadingLocation = true
      hostAppApi.get(`${this.getHostRefApi}stocks/get_product_by_warehouse?product_id=${productid}&warehouse_id=${this.orderModel.warehouseid}`)
        .then((result) => {
          let { locations } = result.data
          locations = locations.map(x => { x.name = x.location_name; return x })
          this.listOfLocations = locations
          this.loadingLocation = false
        })
    },
    getBatchNumbersByLocation (locationid) {
      this.loadingBatch = true
      hostAppApi.post(`${this.getHostRefApi}get_batch_by_location?product_id=${this.productInfo.productid}&location_id=${locationid}&user_id=${this.userId}`)
        .then((result) => {
          const model = result.data
          this.listOfBatchNumbers = model.data || []
          this.loadingBatch = false
        })
    },
    checkIfBatchContainsQty () {
      if (this.selectBatch) {
        const getObj = this.listOfBatchNumbers.find(x => x.batchnumberpwa === this.selectBatch)
        const pickedQty = parseFloat(this.$formatter.replaceCommaWithDot(this.pickQty || 0))
        if (getObj) {
          if (getObj.onstock < pickedQty) {
            this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
            setTimeout(() => {
              this.pickQty = ''
            })
          }
        } else {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.order.notValidBatch') })
          setTimeout(() => {
            this.selectBatch = ''
          })
        }
      }
    },
    getDestinationWarehouseLocations () {
      hostAppApi.get(`${this.getHostRefApi}get_location_by_warehouse/${this.orderModel.destinationwarehouseid || 0}?is_supplier=1&user_id=${this.userId}`)
        .then((result) => {
          const { locations } = result.data
          this.listOfLocations = locations
          if (this.listOfLocations.length === 1) {
            setTimeout(() => {
              const field = this.modeOfView === CONTSTANTS.RECEIVE ? 'name' : 'location_name'
              this.selectedLocation = this.listOfLocations[0][field]
            }, 50)
          }
        })
    },
    getAllLocations () {
      const query = {
        select: ['id', 'name', 'warehouseid', 'isdefault'],
        filter: `isactive eq 1 and warehouseid eq ${this.orderModel.destinationwarehouseid || 0}`,
        order_by: {
          field: 'name',
          asc_or_desc: 'asc'
        }
      }
      this.$api.execute('post', 'warehouselocations/query', query)
        .then(response => {
          this.listOfLocations = response.data
        })
    },
    getOrderProducts () {
      this.loading = true
      this.$api.execute('get', `/warehouseorderitems/get_by_order_pwa/${this.orderId}`)
        .then((response) => {
          this.listOfProducts = []
          if (response.data) {
            const products = response.data
            if (products) {
              if (products) {
                for (let i = 0; i < products.length; i++) {
                  let receivedCount = 0
                  for (let j = 0; j < products[i].receivedorders.length; j++) {
                    const receivedQty = this.$formatter.replaceCommaWithDot(products[i].receivedorders[j].received_quantity)
                    receivedCount += parseFloat(receivedQty)
                  }
                  products[i].received_quantity = ''
                  products[i].receivedqty_ref = receivedCount
                  let pickedCount = 0
                  for (let j = 0; j < products[i].pickedorders.length; j++) {
                    const pickedQty = this.$formatter.replaceCommaWithDot(products[i].pickedorders[j].picked_quantity)
                    pickedCount += parseFloat(pickedQty)
                  }
                  products[i].picked_quantity = ''
                  products[i].pickedqty_ref = pickedCount
                }
              }
              this.listOfProducts = products
            }
            this.restorePreset()
            this.productInfo = {}
            this.scanProduct = ''
            this.productFound = false
            this.focusField('scanProduct')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getRespectiveProductOrder () {
      this.selectedLocation = ''
      this.receivedObj = null
      if (this.scanProduct) {
        this.productLoading = true
        const list = this.$formatter.cloneVariable(this.listOfProducts)
        this.selectedLocationId = 0
        this.locationSelectedAlready = false
        this.isNewProduct = false
        this.productInfo = {}
        if (list) {
          const getProduct = list.find(x => x.barcode === this.scanProduct || x.productnumber === this.scanProduct)
          if (getProduct) {
            this.productFound = true
            this.productInfo = getProduct
            const receivedIndexes = []
            let locationSelected = 0
            getProduct.pickedorders.map(x => {
              if (!locationSelected) locationSelected = x.locationid || 0
              getProduct.receivedorders.map((y, index) => {
                if (y.received_quantity === x.picked_quantity) {
                  receivedIndexes.push(index)
                }
              })
            })
            // this.availableItems = getProduct.order_details.filter((x, index) => !receivedIndexes.includes(index))
            this.availableItems = getProduct.order_details.filter((x, index) => !receivedIndexes.includes(index)).map((y, i) => {
              if (i > 0) y.disabled = true
              return y
            })
            // this.availableItems.shift()
            this.focusField('scanLocation')
            if (this.modeOfView === CONTSTANTS.COLLECT) {
              this.getAvailableLocationsHandler(getProduct.productid)
              setTimeout(() => {
                if (locationSelected) {
                  this.locationSelectedAlready = true
                  this.selectedLocationId = locationSelected
                  this.getBatchNumbersByLocation(this.selectedLocationId)
                } else this.locationSelectedAlready = false
              })
            }
          } else {
            this.selectedLocationId = 0
            let product = await this.getProductByNumber()
            if (product && product.data) {
              this.productFound = true
              product = product.data
              product.productid = product.id
              product.name = product.productname
              this.productInfo = product
              this.isNewProduct = true
              this.getAvailableLocationsHandler(product.id)
            } else {
              this.productInfo = {}
              this.productFound = false
              this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: this.$t('message.product.noProductFound') })
              this.focusField('scanProduct')
              this.scanProduct = ''
            }
          }
          this.restorePreset()
          this.productLoading = false
        }
      }
    },
    getProductByNumber () {
      return hostAppApi.get(`${this.getHostRefApi}get_product_by_search/${this.scanProduct}`)
      /* const model = {
        filter: `number eq '${this.scanProduct}' OR barcode eq '${this.scanProduct}'`,
        additionalfilters: ['isactive eq 1 AND ispackage ne 1'],
        skip: 0,
        limit: 50,
        order_by: {
          field: 'number',
          asc_or_desc: 'asc'
        }
      }
      return this.$api.execute('post', 'products/query', model) */
    },
    receiveQtyOnOrder () {
      const pickedQty = this.$formatter.replaceCommaWithDot(this.pickQty || 0)
      const received = this.$formatter.cloneVariable(this.receivedObj)
      if (!this.productInfo.pickedorders) this.productInfo.pickedorders = []
      // eslint-disable-next-line eqeqeq
      const getSelectedQty = this.productInfo.pickedorders.find(x => x.id == received)
      const receivedQty = getSelectedQty ? this.$formatter.replaceCommaWithDot(getSelectedQty.picked_quantity || 0) : 0
      const qtyValue = this.modeOfView === CONTSTANTS.RECEIVE ? receivedQty : pickedQty
      if (this.$refs.receiveForm.validate() && qtyValue > 0) {
        const locationid = this.selectedLocationId || 0
        const products = this.$formatter.cloneVariable(this.listOfProducts)
        this.saveLoading = true
        let model = products.find(x => x.productid === this.productInfo.productid)
        const otherProducts = products.filter(x => x.productid !== this.productInfo.productid)
        let orderDetailsUpdateIndex = -1
        let listOfItems = []
        if (model) {
          listOfItems = products.filter(x => x.id !== model.id)
          if (locationid) {
            if (!model.receivedorders) model.receivedorders = []
            let receiveOrderDetailsObj = {}
            if (receivedQty && this.modeOfView === CONTSTANTS.RECEIVE) {
              const pickObjToreceive = this.$formatter.cloneVariable(this.availableItems[this.receivedObjIndex])
              model.order_details.every((item, index) => {
                if (_.isEqual(pickObjToreceive, item)) {
                  orderDetailsUpdateIndex = index
                  return false
                }
                return true
              })
              receiveOrderDetailsObj = {
                picked_quantity: pickObjToreceive.picked_quantity,
                picked_date: pickObjToreceive.picked_date,
                received_quantity: pickObjToreceive.picked_quantity,
                received_date: moment().format('DD.MM.YYYY'),
                location_id: pickObjToreceive.locationid,
                location_text: pickObjToreceive.location_text,
                batch_no: pickObjToreceive.batch_no || '',
                serial_number_products: [{
                  batch_no: pickObjToreceive.batch_no,
                  rec_location_id: locationid,
                  rec_location_text: this.selectedLocation
                }],
                newly_received: true,
                newly_picked: false
              }
              // ???
              // model.receivedorders.push(orderDetails)
              // model.receivedorders.push({ batchnumber: this.selectBatch || '', received_quantity: receivedQty, locationid: locationid })
            }
            if (!model.pickedorders) model.pickedorders = []
            if (pickedQty) {
              /* construct received orders */
              const orderDetailsObj = {
                picked_quantity: pickedQty,
                picked_date: moment().format('DD.MM.YYYY'),
                received_quantity: 0,
                received_date: '',
                location_id: locationid,
                location_text: this.selectedLocation,
                batch_no: this.selectBatch || '',
                serial_number_products: [{
                  batch_no: this.selectBatch || ''
                }],
                newly_received: false,
                newly_picked: true
              }
              /* ---ends--- */
              model.pickedorders.push(orderDetailsObj)
              // model.pickedorders.push({ batchnumber: this.selectBatch || '', picked_quantity: pickedQty, locationid: locationid })
            }
            if (!model.order_details) model.order_details = []
            if (this.modeOfView === CONTSTANTS.COLLECT && pickedQty) model.order_details = model.pickedorders
            if (this.modeOfView === CONTSTANTS.RECEIVE && receivedQty && receiveOrderDetailsObj && orderDetailsUpdateIndex > -1) {
              model.order_details[orderDetailsUpdateIndex] = this.$formatter.cloneVariable(receiveOrderDetailsObj)
            }
            model.picked_quantity = pickedQty
          }
        } else {
          model = {}
          model.locationid = locationid
          model.qty = pickedQty
          model.productid = this.productInfo.productid
          if (!model.pickedorders) model.pickedorders = []
          if (!model.receivedorders) model.receivedorders = []
          if (pickedQty) {
            /* construct received orders */
            const orderDetailsObj = {
              picked_quantity: pickedQty,
              picked_date: moment().format('DD.MM.YYYY'),
              received_quantity: 0,
              received_date: '',
              location_id: locationid,
              location_text: this.selectedLocation,
              batch_no: this.selectBatch || '',
              serial_number_products: [{
                batch_no: this.selectBatch || ''
              }],
              newly_received: false,
              newly_picked: true
            }
            /* ---ends--- */
            model.pickedorders.push(orderDetailsObj)
            // model.pickedorders.push({ batchnumber: this.selectBatch || '', picked_quantity: pickedQty, locationid: locationid })
          }
          model.picked_quantity = pickedQty
        }
        listOfItems.push(model)
        listOfItems = [...listOfItems, ...otherProducts]
        if (locationid) {
          const modelObj = { items: listOfItems }
          hostAppApi.post(`${this.getHostRefApi}warehouseorderitems/bulk_save_pwa/${this.orderId}?user_id=${this.userId}&ordertype=transfer&new=${this.isNewProduct}`, modelObj)
            .then((response) => {
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: this.$t('message.common.updatedSuccess') })
              if (response.data && response.data.status === 'received') this.$router.push('/transfer_orders')
              else this.getOrderAndProducts()
            })
            .finally(() => {
              this.saveLoading = false
            })
        } else {
          this.saveLoading = false
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
        }
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
      }
    },
    onResizeHandler () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight - 40 }
    },
    decreaseQty () {
      if (this.pickQty > 0) this.pickQty--
    },
    increaseQty () {
      if (this.pickQty < 0) this.pickQty = ''
      this.pickQty++
    },
    checkIfLocationValid () {
      this.loadingLocation = true
      const locations = this.listOfLocations
      const field = this.modeOfView === CONTSTANTS.RECEIVE ? 'name' : 'location_name'
      const getOnStockValue = locations.find(x => x[field] === this.selectedLocation)
      if (getOnStockValue) {
        const dataField = this.modeOfView === CONTSTANTS.RECEIVE ? 'id' : 'locationid'
        this.selectedLocationId = getOnStockValue[dataField]
        if (this.productInfo.isbatch) this.getBatchNumbersByLocation(this.selectedLocationId)
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.product.noLocFound') })
        setTimeout(() => {
          this.selectedLocation = ''
          this.selectedLocationId = 0
          this.focusField('scanLocation')
        })
      }
      this.loadingLocation = false
    },
    focusField (value) {
      requestAnimationFrame(() => {
        if (this.$refs[value]) {
          this.$refs[value].focus()
        }
      })
    },
    navigateToView () {
      const view = this.modeOfView === CONTSTANTS.RECEIVE ? CONTSTANTS.COLLECT : CONTSTANTS.RECEIVE
      this.$router.push(`/transfer_orders/${this.orderId}/transfer/${view}`)
    },
    restorePreset () {
      this.selectBatch = ''
      this.pickQty = ''
      this.selectedLocation = ''
      this.selectedLocationId = 0
    },
    scanProductByClick (productNumber) {
      this.scanProduct = productNumber
      this.getRespectiveProductOrder()
    }
  }
}
</script>
